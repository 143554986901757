/**
 * Gibt ein HTML Bootstrap Bilder Template für die Galery zurück
 */
var getGalleryImageTemplate = function (index, images_path, image_src, fs) {
  if(fs === true) {
    return '<div data-toggle="modal" data-target="#imageModal'+index+'" class="col-xs-6 col-md-2"><img src="'+images_path+image_src+'" class="img-responsive img-thumbnail center-block"/></div>';
  } else {
    return '<div class="col-xs-6 col-md-2"><img src="'+images_path+image_src+'" class="img-responsive img-thumbnail center-block"/></div>';
  }
};

/**
 * Gibt ein HTML Bootstrap Vollbild Modal Template für die Galery zurück
 */
var getGalleryModalTemplate = function (index, images_path, image_src) {
  return  '<div id="imageModal'+index+'" tabindex="-1" role="dialog" aria-labelledby="imageModalLabel" aria-hidden="true" class="modal fade modal-fullscreen force-fullscreen">'+
            '<div class="modal-dialog">'+
              '<div class="modal-content">'+
               '<div class="modal-header">'+
                  '<button type="button" data-dismiss="modal" aria-hidden="true" class="close">×</button>'+
                  '<h4 class="modal-title">Großansicht</h4>'+
                '</div>'+
                '<div class="modal-body"><img src="'+images_path+''+image_src+'" class="img-center-full"/></div>'+
                '<div class="modal-footer">'+
                  '<button type="button" data-dismiss="modal" class="btn btn-success">Schließen</button>'+
                '</div>'+
              '</div>'+
            '</div>'+
          '</div>';
};

/**
 * Setze einen Navigationspunkt als aktiv
 */
var setActiveNav = function (name) {
  $("li."+name).addClass("active");       // navbar
};

/**
 * Öffne einen Link in einem neuen Tab
 */
var openInNewTab = function (url) {
  var win = window.open(url, '_blank');
  win.focus();
};

/**
 * Karteneinstellungen für die Kontaktseite
 */
var setMap = function (id, link) {
  var map = L.map(id).setView([51.411458, 7.824329], 15);

  L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(map);

  var marker = L.marker([51.411458, 7.824329]).addTo(map)
  .on('click', function() {
    openInNewTab(link);
  });
};

/**
 * Scrolle wieder zum Seitenanfang
 */
var backToTop = function () {
  // scroll body to 0px on click
  $('body,html').animate({
    scrollTop: 0
    }, 800);
};

  
/**
 * Rechner vom Kunden geliefert
 * unpacked with http://www.strictly-software.com/unpacker
 */
  var stop = 0;
{
  var oWidth = 0;
  var nWidth = 0;
  var oRatio = 0;
  var nRatio = 0;
  var oRim = 0;
  var nRim = 0;
  var oSpeed = 0;
  var nSpeed = 0;
  var tachodif = 0;
  var Calculate = function(a) {
    var b = 0;
    var c = a.oWidth.value;
    var d = a.nWidth.value;
    var e = a.oRatio.value;
    var f = a.nRatio.value;
    var g = a.oRim.value;
    var h = a.nRim.value;
    var i = a.oSpeed.value;
    var j = a.umfangdif.value;
    var k = a.Radbreite.value;
    var l = a.Radbreite2.value;
    var m = a.oFB.value;
    var n = a.nFB.value;
    var o = a.oET.value;
    var p = a.nET.value;
    if (c === 0 || e === 0 || g === 0) {
      alert("Bitte Dimension des Basisreifens (linke Spalte) eingeben.");
      b = 1;
    }
    if (d === 0 || f === 0 || h === 0) {
      alert("Bitte Dimension des neuen Reifens (rechte Spalte) eingeben.");
      b = 1;
    }
    if (i === 0) {
      alert("Bitte Fahrgeschwindigkeit eingeben.");
      b = 1;
    }
    if (b === 0) {
      var q = Math.round(c * 0.02 * e) + (g / 0.03937);
      var r = Math.round(d * 0.02 * f) + (h / 0.03937);
      var s = q / r;
      var t = Math.round((n - m) * 25, 4);
      var u = (o - p);
      a.tachodif.value = Math.round((i * s - i) * 100 / i);
      a.nSpeed.value = Math.round(i * s);
      a.Radbreite.value = Math.round(10 * (c / 10));
      a.Radbreite2.value = Math.round(10 * (d / 10));
      a.Radbreitedif.value = Math.round(10 * (d / 10 - c / 10));
      a.oRim2.value = (g);
      a.nRim2.value = (h);
      a.Spurweite.value = (t / 2 + u);
    }
  };
  var rechnen = function(a, f, b, h, c, d, e) {
    var g = 0;
    var i = 0;
    a.durchmesser.value = Math.round(f * 25.4 + 2 * (b * h / 100));
    d1 = Math.round(f * 25.4 + 2 * (b * h / 100));
    g = Math.round((f * 25.4 + 2 * (b * h / 100)) * 3.0500);
    a.umfang.value = g;
    a.durchmesser2.value = Math.round(c * 25.4 + 2 * (d * e / 100));
    d2 = Math.round(c * 25.4 + 2 * (d * e / 100));
    i = Math.round((c * 25.4 + 2 * (d * e / 100)) * 3.0500);
    a.umfang2.value = i;
    a.differenz.value = Math.round(i * 1000 / g - 1000) / 10;
    a.boden.value = Math.round((d2 * 10) - (d1 * 10)) / 20;
    a.umfangdif.value = Math.round(10 * (i - g)) / 10;
    a.durchmesserdif.value = Math.round(10 * (d2 - d1)) / 10;
  };
}

/**
 * Entfernt wieder alle bearbeitbaren Elemente von der Seite
 */
var reset = function () {
  $('.editarea').empty();
};

/**
 * Fügt ein WYSIWYG-Editor der Seite hinzu um HTML zu bearbeiten
 */
var appendWYSIWYG = function (html, id, label, onChange) {

  html = html_beautify(html);

  if(typeof(id) === 'undefined') {
    id = '';
  }
  var wysiwygSelector = 'textarea_'+id;
  var aceSelector = 'ace'+id;
  $('.editarea').append('<div id="'+wysiwygSelector+'" class="textarea '+wysiwygSelector+'">'+html+'</div>');
  $('.editarea').append('<div id="'+aceSelector+'" class="aceEditor"></div>');
  $('.editarea').append('<hr />');

  // ============== WYSIQYG Editor ==============
  var wysihtmlEditorOptions = {
    toolbar: {
      "fa": true,
      "font-styles": true, //Font styling, e.g. h1, h2, etc. Default true
      "emphasis": true, //Italics, bold, etc. Default true
      "lists": true, //(Un)ordered lists, e.g. Bullets, Numbers. Default true
      "html": false, //Button which allows you to edit the generated HTML. Default false
      "link": true, //Button to insert a link. Default true
      "image": true, //Button to insert an image. Default true,
      "color": true, //Button to change color of font  
      "blockquote": true, //Blockquote  
      "size": 'none' //default: none, other options are xs, sm, lg
    },
    parserRules: {
      tags: {
        dl: {},
        dt: {}
      }
    },
    events: {
      load: function() { 
        // console.log("Loaded!");
      },
      change: function(e) {
        var newValue = $('.'+wysiwygSelector).html();
        // console.log('[wysihtmlEditor] content changed', newValue);
        // set new value to html editor
        aceEditor.setValue(newValue);
        html = newValue;
        onChange(e, newValue);
      }
    },
  };
  $('#'+wysiwygSelector).wysihtml5(wysihtmlEditorOptions);

  // ============== HTML Editor ==============
  var aceEditor = ace.edit(aceSelector);
  aceEditor.setAutoScrollEditorIntoView(true);
  aceEditor.setTheme("ace/theme/monokai");
  aceEditor.getSession().setMode("ace/mode/html");
  aceEditor.setValue(html);
  aceEditor.clearSelection();
  aceEditor.on("change", function(e) {
    var newValue = aceEditor.getValue();
    // console.log('[aceEditor] content changed', newValue);
    // set new value to wysiwyg editor
    $('.'+wysiwygSelector).html(newValue);
    html = newValue;
    onChange(e, newValue);
  });
};

/**
 * Fügt ein input der Seite hinzu um Texte ohne HTML zu bearbeiten
 */
var appendInput = function (string, id, label, onChange) {
  if(typeof(id) === 'undefined') {
    id = 'input_0';
  }
  $('.editarea').append(''+
    '<div class="form-group">'+
      '<label for="'+id+'">'+label+'</label>'+
      '<input type="text" class="form-control" id="'+id+'" placeholder="'+label+'">'+
    '</div>'+
    '<hr />'
  );

  $('#'+id).val(string).change(function(e) {
    var newValue = $(this).val();
    onChange(e, newValue);
  });
};

/**
 * Gibt den Index vom Array `array` einer Eigenschaft `property` mit Wert `value` zurück
 */
var getIndexOfProperty = function (array, property, value) {
  var index = -1;
  for (var i = 0; i < array.length; i++) {
    if(array[i][property] === value) {
      index = i;
    }
  }
  return index;
};

/**
 * Löscht einen Benutzer aus der Tabelle aller angemeldeter Benutzer
 */
var deleteSessionBlockTr = function (session_id) {
  $('tr#'+session_id).empty();
};

var deleteSession = function (session_id) {
  var index = getIndexOfProperty(window.currentData.sessions, 'id', session_id);
  console.log("[deleteSession]", session_id, index, window.currentData.sessions[index]);
  if (index > -1) {
    window.currentData.sessions.splice(index, 1);
    deleteSessionBlockTr(session_id);
  }
  return window.currentData;
};

/**
 * Erzeugt eine Tabelle mit allen angemeldeten Benutzern
 */
var appendSessionBlock = function (sessions, onChange) {
  var html = ''+
  '<h1>Angemeldete Benutzer</h1>'+
  '<table class="table table-hover" id="sessions-table">'+
    '<thead>'+
      '<tr>'+
        '<th>#</th>'+
        '<th>Benutzername</th>'+
        '<th>Session ID</th>'+
        '<th>Aktion</th>'+
      '</tr>'+
    '</thead>'+
    '<tbody>';

    $.each(sessions, function(index, session) {
      html += ''+
      '<tr id="'+session.id+'">'+
        '<th scope="row">'+index+'</th>'+
        '<td>'+session.user+'</td>'+
        '<td>'+session.id+'</td>'+
        '<td>'+
          '<button type="button" class="btn btn-danger btn-sm", onclick="deleteSession(\''+session.id+'\')">Abmelden</button>'+
        '</td>'+
      '</tr>';
    });

    html += ''+
    '</tbody>'+
  '</table>';

  $('.editarea').append(html);
};

/**
 * Fügt Bearbeitbare Module der Seite hinzu um Inhalte zu ändern
 */
var appendObject = function (blocks, index, inputlabel, onChange) {

  if(typeof(index) === 'undefined' || index === null) {
    index = 0;
  }

  if(typeof(inputlabel) === 'undefined' || inputlabel === null) {
    inputlabel = 'title';
  }

  // follow currently only used on `startseite`
  $.each(blocks, function(key, property) {
    switch(key) {
      case 'img_name':
        appendInput(property, 'input_'+key+'_'+index, 'Bild Dateiname', function (event, text) {
          blocks[key] = html;
          onChange(event, blocks, index, key, text);
        });
        break;
      case 'html':
        appendWYSIWYG(property, index, 'content', function (event, html) {
          blocks[key] = html;
          // console.log("[appendObject] change", blocks);
          onChange(event, blocks, index, key, html);
        });
        break;
      case 'title':
        appendInput(property,  'input_'+key+'_'+index, 'Titel', function (event, text) {
          blocks[key] = text;
          onChange(event, blocks, index, key, text);
        });
        break;
      case 'blocks':
        console.log(property);
        $.each(property, function(index2, block) {
          appendObject(block, Number(index+'0'+index2), 'block_title', function (event, block) {
            blocks[key][index2] = block;
            onChange(event, blocks, index, key, block);
          });
        });
        break;
      case 'gallery':
        console.log('property '+key+' currently not supported'); // TODO
        break;
      case 'pdf':
        appendInput(property, 'input_'+key+'_'+index, 'Anhang', function(event, text) {
          blocks[key] = text;
          onChange(event, blocks, index, key, text);
        });
        break;
      case 'users':
        $.each(property, function(index2, user) {
          appendInput(user.name, 'input_'+key+'_name_'+index2, 'Benutzername', function (event, text) {
            blocks[key][index2].name = text;
            onChange(event, blocks, index, key, text);
          });
          user.newPass = '';
          appendInput(user.newPass, 'input_'+key+'_newPass'+index2, 'Neues Passwort', function (event, text) {
            blocks[key][index2].newPass = text;
            onChange(event, blocks, index, key, text);
          });
        });
        break;
      case 'sessions':
        appendSessionBlock(property, function (event, sessions) {

        });
        break;
      default:
        // startseite
        if(key === 'abrollpruefung' ||
          key === 'anbaupruefung' ||
          key === 'felgenhornpruefung' ||
          key === 'header' ||
          key === 'jumbotron' ||
          key === 'korrosionspruefung' ||
          key === 'leistungen' ||
          key === 'reihenfertigung' ||
          key === 'umlaufbiegepruefung' ||
          key === 'wechseltorsionspruefung' ||
          key === 'werkstoffpruefung'
        ) {
          appendObject(property, key, key, function (event, block) {
            blocks[key] = block;
          });
        } else {
          console.log('property '+key+' currently not supported');
        }
    }
  });
};

/**
 * Initialisiert die Admin Sidebar
 * Macht nodes auswählbar, lädt entsprechenden JSON-Content vom PHP Framework, etc
 */
var initAdminSidebar = function () {
  window.currentData = null;
  window.currentNode = null;
  $('#tree').treeview({data: treeview})
  .on('nodeSelected', function(event, node) { // Element aus der Sidebar wurde ausgewählt
    window.currentNode = node;
    // console.log('node', node);
    if(window.currentNode.type === 'json') {
      $.post("/admin/json", {node:window.currentNode}, function(data) {
        window.currentData = JSON.parse(data);
        console.log("currentData", window.currentData);
        reset();
        // titles content wurde aufgerufen
        if(window.currentNode.text === 'titles') {
          $.each(window.currentData, function(key, title) {
            // console.log(title, key);
            if(typeof(title) !== 'undefined') {
              appendInput(title, 'title'+key, key, function (event, block) {
                window.currentData[key] = block;
                console.log('['+key+'] changed', window.currentData);
              });
            }
          });
        } else {
          appendObject(window.currentData, null, null, function (event, block) {
            window.currentData = block;
            console.log('[Anything] changed', window.currentData);
          });
        }

        toggleHtmlMode(isHtmlMode());
      });
    }
  });
};

/**
 * Überprüft ob der HTML Modus gerade aktiv ist
 */
var isHtmlMode = function () {
  var isChecked = $("#htmlmode").is(':checked');
  return isChecked;
};

/**
 * (de)aktiviere HTML Modus
 */
var toggleHtmlMode = function (show) {
  if(show === true) {
    $('.aceEditor').css('margin-left', '0px');
    $('.aceEditor').css('margin-bottom', '0px');
  } else {
    $('.aceEditor').css('margin-left', '1000000px');
    $('.aceEditor').css('margin-bottom', '-200px');
  }
};

/**
 * Speichert Content-Änderungen aus der Admin per AJAX
 */
var saveAdmin = function () {
  console.log("[saveAdmin]", window.currentData);
  $.post("/admin/save", {
    block: window.currentData,
    node: window.currentNode,
  }, function(data) {
    console.log("currentData", window.currentData);
  });
};
// .imagesarea
var appendImagesPreview = function (imagetree, startMasonry) {
  console.log("imagetree", imagetree);
  var html = '';
  $.each(imagetree, function(index, file) {
    if(file.type === "file") {
      html += ''+
        '<div class="col col-xs-12 col-sm-4 col-md-3">'+
          '<img class="img-responsive center-block" src="/include/automotive/images/'+encodeURI(file.path)+'" onclick="alert(\'/include/automotive/images/'+encodeURI(file.path)+'\');" style="padding-bottom: 10px;">'+
        '</div>';

      // 
    } else if (file.type === "dir") {
      appendImagesPreview(file.nodes, false);
    }
  });

  $('#imagesarea').append(html);

  if(startMasonry) {
    var $imagesarea = $('#imagesarea').masonry({
      itemSelector: '.col',
      columnWidth: '.col-xs-12.col-sm-4.col-md-3',
    });

    $imagesarea.imagesLoaded().progress( function() {
      $imagesarea.masonry('layout');
    });
  }
  

};